import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

export default function useUsername(): ComputedRef<string> {
  const store = useStore();

  return computed(() => {
    return store.state.cabinet.user?.profile?.firstName || store.getters['cabinet/getLoginPhone'];
  });
}
