import { inject } from 'vue';
import { IS_AUTH_COOKIE_TAG } from '@/constants/cookie-tag';
import { VueCookies } from 'vue-cookies';
import useTildaUser from '@/composables/use-tilda-user';
import { useStore } from 'vuex';

export default function useLogout() {
  const cookies = inject<VueCookies>('$cookies');
  const store = useStore();
  const tildaUser = useTildaUser();

  const logOut = async () => {
    if (cookies) {
      cookies.remove(IS_AUTH_COOKIE_TAG);
    }

    tildaUser.removeLS();
    await store.dispatch('cabinet/logoutFromCabinet');
    window.history.pushState('', document.title, window.location.pathname);
    window.location.pathname = `/${store.getters.currentLocaleISO}/`;
  };

  return {
    logOut,
  };
}
